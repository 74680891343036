
  export default {
    props: {
      placeholder: {
        type: String,
        default: '',
      },
    },
    data(){
      return {
        search: '',
      }
    },

    methods: {
      inputSearch(){
        this.$emit('result-query', this.search)
      }
    }
  }

