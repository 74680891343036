
export default {
  name: 'InformationIndex',
  data() {
    return {
      inValid: false,
      showConfirm: false,
      phoneCountry: {},
      nameError: '',
      emailError: '',
      phoneError: '',
      messageError: '',
      name: '',
      email: '',
      phone: '',
      message: '',
      loading: false
    }
  },

  methods: {
    handleCountryPhone(value) {
      this.phoneCountry.phoneCode = value.dialCode
    },

    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },

    validatePhone(phone) {
      const re = /^[0-9]+$/
      return re.test(String(phone).split(' ').join('').toLowerCase())
    },

    async submitForm() {
      if (this.name.length < 1) {
        this.nameError = 'The Name field is required!'
      } else if (this.name.length > 100) {
        this.nameError = 'Name must be shorter than or equal to 100 characters'
      } else {
        this.nameError = ''
      }

      if (this.email.length < 1) {
        this.emailError = 'The Email field is required!'
      } else if (this.email.length > 100) {
        this.emailError = 'Email must be shorter than or equal to 100 characters'
      } else if (!this.validateEmail(this.email)) {
        this.emailError = 'The Email field is invalid!'
      } else {
        this.emailError = ''
      }

      if (this.phone.length < 1) {
        this.phoneError = 'The Phone number field is required!'
      } else if (this.phone.length > 100) {
        this.phoneError =
          'Phone must be shorter than or equal to 100 characters'
      } else if (!this.validatePhone(this.phone)) {
        this.phoneError = 'The Phone number field is invalid!'
      } else {
        this.phoneError = ''
      }

      if (this.message.length < 10 || this.message.length > 500) {
        this.messageError = 'Message must be from 10 to 500 characters'
      } else {
        this.messageError = ''
      }

      if (
        this.nameError === '' &&
        this.emailError === '' &&
        this.phoneError === '' &&
        this.messageError === ''
      ) {
        this.loading = true
        const phoneConvert = '+' + this.phoneCountry.phoneCode + ' ' + this.phone.split(' ').join('');
        const { result }  = await this.$request('/partners', {
          options: {
            method: 'POST',
            payload: {
              name: this.name,
              email: this.email,
              whatsApp: phoneConvert,
              message: this.message || '',
            },
          },
        });

        if (result.status === 201) {
          this.showConfirm = true
          this.showNotification()
          this.loading = false
        }
      }
    },

    showNotification() {
      setTimeout(() => {
        this.onClickConfirm()
      }, 3000)
    },

    onClickConfirm() {
      this.showConfirm = false
      this.name = ''
      this.email = ''
      this.phone = ''
      this.message = ''
    },
  },
}
